// src/components/Views/MessagesView.js
import React, { useEffect } from 'react';
import MessagesPrompt from '../components/MessagesPrompt';
import ConversationList from '../components/ConversationList';
import styled from 'styled-components';
import { useNotifications } from '../context/NotificationContext'; // Adjust import path if necessary

const FixedHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px; /* Adjust height as needed */
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 40; /* Ensure it stays above other elements */
`;

const HeaderTitle = styled.h1`
  font-size: 2rem;
  color: #333333;
  margin: 0;
  font-weight: bold;
`;

// Adjust BodyContainer to account for the fixed header
const BodyContainer = styled.div`
  position: relative;
  justify-content: center;
    width: 100%;
margin: 0 auto; /* Center content */
  padding: 80px 0px; /* Added padding-top to prevent overlap with the fixed header */
`;


// Styled component for when MessagesPrompt is displayed
const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 80px 20px;
  min-height: calc(100vh - 80px);
  box-sizing: border-box;
  text-align: center; /* Ensure text is centered */
`;

// Styled component for ConversationList
const ConversationsContainer = styled.div`

`;


// New styled component for "No conversations found" state
const NoConversationsContainer = styled.div`
  text-align: center;
  padding: 50px 20px;
  color: #777;
`;


const NoText = styled.p`
  font-size: 1.5rem;
  margin: 0;
`;

export default function MessagesView({ currentUser, conversations, loading, error }) {
  const { clearNotification } = useNotifications(); // Access clearNotification

  useEffect(() => {
    // Clear messages notification when the MessagesView is mounted
    clearNotification('messages');
  }, [clearNotification]);

  return (
    <div>
      <FixedHeader>
        <HeaderTitle>My Messages</HeaderTitle>
      </FixedHeader>
      {!currentUser ? (
        <CenteredContainer>
          <MessagesPrompt />
        </CenteredContainer>
      ) : (
        <BodyContainer>
          {loading ? (
            <p>Loading conversations...</p>
          ) : error ? (
            <p>{error}</p>
          ) : conversations.length === 0 ? (
            <NoConversationsContainer>
              <NoText>No Messages Found</NoText>
            </NoConversationsContainer>
          ) : (
            <ConversationsContainer>
              <ConversationList conversations={conversations} currentUser={currentUser} />
            </ConversationsContainer>
          )}
        </BodyContainer>
      )}
    </div>
  );
}