import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Input } from "liamc9npm";
import { ChevronLeftIcon } from "liamc9npm"; 
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ProfilePic from "../components/ProfilePic";
import DeleteModal from "../components/DeleteModal"; // Ensure you have this component

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
`;


const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`;
const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin: 0;
  margin-left: 4rem;
`;
const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  padding: 5px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 50;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const SaveButton = styled.button`
  cursor: pointer;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #A855F7;
  border: none;
  border-radius: 8px;

  &:disabled {
    background-color: #777;
    cursor: not-allowed;
  }
`;

const ProfileImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const EditText = styled.span`
  font-size: 0.875rem;
  color: #666;
  text-align: center;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const InputField = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 20px;
`;
const DeleteButton = styled.button`
  font-size: 1rem;
  color: #e74c3c;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 40px;

`;

const ProfileView = ({
  firstName,
  setFirstName,
  age,
  setAge,
  profilePic,
  fileInputRef,
  handleSaveChanges,
  isSaving,
  currentUser,
  setProfilePic,
  userData,
  setNewProfilePicFile,
  newProfilePicFile,
  collegeEmblem,
  showDeleteModal,
  setShowDeleteModal,
  confirmDeleteAccount,
}) => {
  const handleProfilePicSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        toast.error("Only JPEG, PNG, and GIF files are allowed.");
        return;
      }

      setNewProfilePicFile(file);
      const previewURL = URL.createObjectURL(file);
      setProfilePic(previewURL);
    }
  };

  useEffect(() => {
    return () => {
      if (newProfilePicFile) {
        URL.revokeObjectURL(profilePic);
      }
    };
  }, [newProfilePicFile, profilePic]);

  const handleBackClick = () => {
    window.history.back();
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteAccount = () => {
    setShowDeleteModal(true);
  };

  const cancelDeleteAccount = () => {
    setShowDeleteModal(false);
  };

  return (
    <ProfileContainer>
      <Header>
        <BackButton onClick={handleBackClick} aria-label="Go back to settings">
          <ChevronLeftIcon />
        </BackButton>
        <Title>Profile</Title>
      </Header>

      <ProfileImageWrapper>
        <ProfilePic
          profilePic={profilePic}
          emblem={collegeEmblem}
          onClick={triggerFileInput}
        />
        <EditText>Click to edit</EditText>
        <HiddenFileInput
          type="file"
          accept="image/*"
          onChange={handleProfilePicSelect}
          ref={fileInputRef}
        />
      </ProfileImageWrapper>

      <InputField>
        <Input
          name="firstName"
          label="First Name"
          type="text"
          color="#A855F7"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Enter your first name"
        />
      </InputField>

      <InputField>
        <Input
          name="age"
          label="Age"
          type="number"
          color="#A855F7"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          placeholder="Enter your age"
        />
      </InputField>

      <SaveButton onClick={handleSaveChanges} disabled={isSaving}>
        {isSaving ? "Saving..." : "Save Changes"}
      </SaveButton>

      <DeleteButton onClick={handleDeleteAccount}>
        Delete Account
      </DeleteButton>

      {showDeleteModal && (
       <DeleteModal
       onCancel={cancelDeleteAccount}
       onConfirm={confirmDeleteAccount}
       title="Delete Your Account"
       message="Are you sure you want to delete your account? This action cannot be undone."
       requiresPassword={
         currentUser.providerData &&
         currentUser.providerData[0].providerId === "password"
       }
     />
      )}
    </ProfileContainer>
  );
};

export default ProfileView;
