import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  serverTimestamp,
  arrayUnion,
  writeBatch,
  getDoc,
  collection,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { useAuth } from "../context/AuthContext";
import { useDocument } from "react-firebase-hooks/firestore";
import RoomsView from "../views/RoomsView";
import { useNotifications } from "../context/NotificationContext";

// 1) Import these from Firebase
import { getFunctions, httpsCallable } from "firebase/functions";

const Rooms = () => {
  const { id } = useParams(); // Extract the `id` from the URL parameters
  const { currentUser, userData } = useAuth(); // Access the current user from AuthContext
  const [roomData, setRoomData] = useState(null);
  const { addNotification } = useNotifications();

  const [roomSnapshot, roomLoading, roomError] = useDocument(
    doc(db, "listings", id)
  );

  console.log(roomData);

  useEffect(() => {
    if (roomSnapshot) {
      const data = {
        id: roomSnapshot.id, // Include the document ID
        ...roomSnapshot.data(), // Spread the document data
      };
      console.log("Fetched room data:", data); // Debugging
      setRoomData(data);
    }
  }, [roomSnapshot]);

  const handleSend = async (message) => {
    try {
      if (!roomData) throw new Error("Room data is not available.");
      if (!currentUser) throw new Error("User is not authenticated.");
      if (!message || typeof message !== "string") throw new Error("Invalid message content.");

      const senderUID = currentUser.uid;
      const receiverUID = roomData.userId;
      if (!receiverUID) throw new Error("Receiver UID is not defined.");

      const receiverDocRef = doc(db, "users", receiverUID);
      const receiverDocSnap = await getDoc(receiverDocRef);
      if (!receiverDocSnap.exists()) throw new Error("Receiver user data not found.");

      const receiverData = receiverDocSnap.data();
      const now = serverTimestamp();

      const conversationData = {
        listingId: id,
        participants: [
          {
            uid: senderUID,
            name: userData.displayName || "Sender Name",
            avatarUrl: userData.photoURL || "",
            universityEmblem: userData.universityEmblem || "",
          },
          {
            uid: receiverUID,
            name: receiverData.displayName || "Receiver Name",
            avatarUrl: receiverData.photoURL || "",
            universityEmblem: receiverData.universityEmblem || "",
          },
        ],
        messages: [
          {
            sender: senderUID,
            text: message,
            localTimestamp: Date.now(),
          },
        ],
        lastMessage: {
          text: message,
          timestamp: now,
        },
        createdAt: now,
      };

      const batch = writeBatch(db);
      const conversationRef = doc(collection(db, "conversations"));
      batch.set(conversationRef, conversationData);

      const conversationID = conversationRef.id;

      const senderDocRef = doc(db, "users", senderUID);
      batch.set(
        senderDocRef,
        { conversationIDs: arrayUnion(conversationID) },
        { merge: true }
      );

      batch.set(
        receiverDocRef,
        { conversationIDs: arrayUnion(conversationID) },
        { merge: true }
      );

      // Update receiver's notifications.messages to true
      batch.set(
        receiverDocRef,
        { notifications: { messages: true } },
        { merge: true }
      );

      await batch.commit();
      console.log("Message sent successfully.");

      const emailData = {
        email: receiverData.email,
        sendingUser: userData.displayName || "Sender Name",
        receivingUser: receiverData.displayName || "Receiver Name",
      };

      // Trigger notification & email for sender side using NotificationContext
      addNotification("messages", emailData);

    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    }
  };
  
  // Handle Loading and Error States
  if (roomLoading) {
    return <div>Loading room data...</div>;
  }

  if (roomError) {
    return <div>Error loading room data: {roomError.message}</div>;
  }

  return (
    <RoomsView
      roomData={roomData}
      handleSend={handleSend}
      currentUser={currentUser}
      id={id}
      userData={userData}
    />
  );
};

export default Rooms;
