import React, { useState } from 'react';
import styled from 'styled-components';
import { SortLogic, SortIcon } from 'liamc9npm';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
`;

const SortingInfo = styled.div`
  font-size: 1rem;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 80px; /* Narrower width for the select button */
`;

const CustomSelect = styled.select`
  /* Remove padding-left since we'll use the overlay for the chevron */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem; /* Uniform padding */
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  width: 100%; 
`;

const ChevronWrapper = styled.div`
  position: absolute;
  right: 0.5rem; /* Position the chevron inside the select button */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* So clicks go through to the select */
  display: flex;
  align-items: center;

  .svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

const sortingOptions = [
  { label: 'Sort', comparator: null },
  { 
    label: 'Recently Posted', 
    comparator: (a, b) => {
      // Ensure both items have a createdAt field
      const aTime = a.createdAt && typeof a.createdAt.toMillis === 'function' 
                      ? a.createdAt.toMillis() 
                      : 0;
      const bTime = b.createdAt && typeof b.createdAt.toMillis === 'function' 
                      ? b.createdAt.toMillis() 
                      : 0;
      return bTime - aTime;
    }
  },
  { label: 'Rent: Low to High', comparator: (a, b) => a.rent - b.rent },
  { label: 'Rent: High to Low', comparator: (a, b) => b.rent - a.rent },
];





const Sort = ({ items, onSortedChange }) => {
  const { updateSort } = SortLogic({ items, onSortedChange });
  const [selectedLabel, setSelectedLabel] = useState(null);

  const handleChange = (e) => {
    const selectedOption = sortingOptions.find(opt => opt.label === e.target.value);
    setSelectedLabel(selectedOption?.label !== 'Sort' ? selectedOption.label : null);
    updateSort(selectedOption?.comparator || null);
  };

  return (
    <Container>
      <SortingInfo>
        Sorted by: <strong>{selectedLabel ?? 'None'}</strong>
      </SortingInfo>
      <SelectWrapper>
        <CustomSelect onChange={handleChange} value="Sort">
          {sortingOptions.map(option => (
            <option key={option.label} value={option.label}>
              {option.label}
            </option>
          ))}
        </CustomSelect>
        <ChevronWrapper>
          <SortIcon className="svg" />
        </ChevronWrapper>
      </SelectWrapper>
    </Container>
  );
};

export default Sort;
