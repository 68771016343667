import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon, UserIcon2 } from 'liamc9npm';
import { EditStackedList } from 'liamc9npm';
import { useAuth } from '../context/AuthContext';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';

// Styled Components
const Container = styled.div`
  max-width: 28rem;
  padding: 1rem;
  margin: 0 auto;
  position: relative;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`;
const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin: 0;
  margin-left: 4rem;
`;
const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  padding: 5px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 50;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const MarketingPreferences = () => {
  const { currentUser, userData } = useAuth();
  const db = getFirestore();
  const [notifications, setNotifications] = useState(true);

  // Initialize the toggle value from userData if available
  useEffect(() => {
    if (userData && typeof userData.receiveEmails === 'boolean') {
      setNotifications(userData.receiveEmails);
    }
  }, [userData]);

  // Handler to update notification preference in both state and Firestore
  const handleNotificationToggle = async (newValue) => {
    setNotifications(newValue);

    try {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, {
          receiveEmails: newValue,
        });
      }
    } catch (error) {
      console.error('Error updating notification preference:', error);
      // Optionally revert the toggle state if update fails
      setNotifications(prev => !prev);
    }
  };

  const accountItems = [
    {
      type: 'ToggleField',
      props: {
        icon: UserIcon2,
        name: 'Email Marketing',
        value: notifications,
        onChange: handleNotificationToggle,
      },
    },
  ];

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <Container>
      {/* Header with Back Button and Title */}
      <Header>
        <BackButton onClick={handleBackClick} aria-label="Go back to settings">
          <ChevronLeftIcon />
        </BackButton>
        <Title>Marketing</Title>
      </Header>

      {/* Edit List */}
      <EditStackedList items={accountItems} />
    </Container>
  );
};

export default MarketingPreferences;
