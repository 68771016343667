import React, { useState, useEffect, useRef } from "react";
import ProfileView from "../views/ProfileView";
import { useAuth } from "../context/AuthContext";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc, getDoc, writeBatch } from "firebase/firestore";
import { db } from "../firebase-config";
import { toast } from "react-toastify";
import { 
  deleteUser, 
  GoogleAuthProvider, 
  reauthenticateWithPopup,
  EmailAuthProvider,
  reauthenticateWithCredential 
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

// Define the placeholder URL constant
const PLACEHOLDER_PHOTO_URL = "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541";

const Profile = () => {
  const { currentUser, userData, updateUserData, logout } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [age, setAge] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [newProfilePicFile, setNewProfilePicFile] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const storage = getStorage();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleSaveChanges = async () => {
    // Validate input fields
    if (!firstName.trim()) {
      toast.error("First name cannot be empty.");
      return;
    }

    if (!age || isNaN(age) || age <= 0) {
      toast.error("Please enter a valid age.");
      return;
    }

    // Check if a custom profile picture is provided
    // If no new file is selected and current profile picture is the placeholder
    if (!newProfilePicFile && profilePic === PLACEHOLDER_PHOTO_URL) {
      toast.error("A profile picture is required.");
      return;
    }

    try {
      setIsSaving(true);
      let downloadURL = userData?.photoURL || PLACEHOLDER_PHOTO_URL;

      // Upload new profile picture if provided
      if (newProfilePicFile) {
        const storageRef = ref(storage, `profile_pictures/${currentUser.uid}/${newProfilePicFile.name}`);
        await uploadBytes(storageRef, newProfilePicFile);
        downloadURL = await getDownloadURL(storageRef);
      }

      const docRef = doc(db, "users", currentUser.uid);
      const isProfileComplete = firstName.trim() !== "" && downloadURL !== PLACEHOLDER_PHOTO_URL;

      // Update Firestore document
      await updateDoc(docRef, {
        displayName: firstName,
        age,
        photoURL: downloadURL,
        profileComplete: isProfileComplete,
      });

      // Update local user data context
      await updateUserData({
        displayName: firstName,
        age,
        photoURL: downloadURL,
        profileComplete: isProfileComplete,
      });

      setNewProfilePicFile(null);
      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Failed to save changes. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const confirmDeleteAccount = async (password) => {
    try {
      if (!currentUser) {
        throw new Error("No authenticated user found.");
      }
  
      const providerData = currentUser.providerData;
      if (providerData.length === 0) {
        throw new Error("No provider data available.");
      }
  
      const providerId = providerData[0].providerId;
  
      if (providerId === "google.com") {
        const provider = new GoogleAuthProvider();
        await reauthenticateWithPopup(currentUser, provider);
      } else if (providerId === "password") {
        if (!password) {
          throw new Error("Password is required for re-authentication.");
        }
        const credential = EmailAuthProvider.credential(
          currentUser.email,
          password
        );
        await reauthenticateWithCredential(currentUser, credential);
      } else {
        throw new Error(`Unsupported provider: ${providerId}`);
      }
  
      const userDocRef = doc(db, "users", currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
  
      if (!userDocSnap.exists()) {
        throw new Error("User document does not exist.");
      }
  
      const userDocData = userDocSnap.data();
      const listings = userDocData.listings || [];
  
      const batch = writeBatch(db);
  
      listings.forEach((listingId) => {
        const listingDocRef = doc(db, "listings", listingId);
        batch.delete(listingDocRef);
      });
  
      batch.delete(userDocRef);
  
      await batch.commit();
  
      await deleteUser(currentUser);
  
      await logout();
  
      toast.success("Account and associated listings deleted successfully.");
      navigate("/login");
    } catch (error) {
      console.error("Failed to delete account and listings:", error);
  
      if (error.code === "auth/wrong-password") {
        toast.error("Incorrect password. Please try again.");
      } else if (error.code === "auth/requires-recent-login") {
        toast.error("Please re-authenticate to delete your account.");
      } else if (error.message.includes("Unsupported provider")) {
        toast.error(error.message);
      } else {
        toast.error("Failed to delete account. Please try again later.");
      }
    } finally {
      setShowDeleteModal(false);
    }
  };
  
  useEffect(() => {
    if (userData) {
      setFirstName(userData.displayName || "");
      setProfilePic(userData.photoURL || PLACEHOLDER_PHOTO_URL);
      setAge(userData.age || "");
    }
  }, [userData]);

  return (
    <ProfileView
      firstName={firstName}
      setFirstName={setFirstName}
      age={age}
      setAge={setAge}
      profilePic={profilePic}
      fileInputRef={fileInputRef}
      handleSaveChanges={handleSaveChanges}
      isSaving={isSaving}
      currentUser={currentUser}
      setProfilePic={setProfilePic}
      userData={userData}
      setNewProfilePicFile={setNewProfilePicFile}
      newProfilePicFile={newProfilePicFile}
      collegeEmblem={userData?.universityEmblem || null}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
      confirmDeleteAccount={confirmDeleteAccount}
    />
  );
};

export default Profile;
