// src/components/ProfilePictureWithEmblem.js
import React from "react";
import styled from "styled-components";

const ImageWrapper = styled.div`
  width: ${(props) => props.size || "120px"};
  height: ${(props) => props.size || "120px"};
  position: relative;
  border-radius: 50%;
  background-image: url(${(props) => props.profilePic});
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

const EmblemImage = styled.img`
  position: absolute;
  width: ${(props) => props.size ? `calc(${props.size} / 2.5)` : "40px"};
  height: ${(props) => props.size ? `calc(${props.size} / 2.5)` : "40px"};
  border-radius: 50%;
  bottom: -5px;
  right: -5px;
  border: 2px solid white;
  background-color: white;
`;

const ProfilePic = ({
  profilePic = "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541", // default fallback
  emblem,
  size,
  onClick,
  ...props
}) => {
  return (
    <ImageWrapper profilePic={profilePic} size={size} onClick={onClick} {...props}>
      {emblem && <EmblemImage src={emblem} alt="College Emblem" size={size} />}
    </ImageWrapper>
  );
};

export default ProfilePic;
