// ExploreView.jsx
import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Feed from '../components/search/Feed';
import Sort from '../components/search/Sort';
import FilterDrawer from '../components/search/FilterDrawer';
import { getDistance } from 'geolib';

// Example styled containers:
const ExploreViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  scrollbar-width: none;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  position: fixed;   /* Fix the header at the top */
  top: 0;
  left: 0;
  right: 0;
  background: white; /* Ensure background covers content beneath */
  z-index: 40;    /* Ensure it’s above other content */
`;


const FilterContainer = styled.div`
`;

const SortContainer = styled.div`
margin: 10px 20px;
`;

const ScrollableFeedContainer = styled.div`
  width: 100%;
  height: calc(100vh - 71px); /* Adjust height based on the header’s height */
  overflow-y: auto;
  margin-top: 71px; /* Push content below the fixed header if necessary */
`;

const universityCoordinates = {
  'ATU': { lat: 54.27437, lng: -8.47504 },
  'DCU': { lat: 53.38680, lng: -6.25674 },
  'DKIT': { lat: 53.9860, lng: -6.4048 },
  'IADT': { lat: 53.29300, lng: -6.15428 },
  'MIE': { lat: 53.37094, lng: -6.25119 },
  'MIC': { lat: 52.67391, lng: -8.56753 },
  'MU': { lat: 53.38186, lng: -6.59307 },
  'MTU': { lat: 52.26977, lng: -9.69447 },
  'NCAD': { lat: 53.33831, lng: -6.29722 },
  'NCI': { lat: 53.33958, lng: -6.24978 },
  'RCSI': { lat: 53.33923, lng: -6.25659 },
  'RIA': { lat: 53.33797, lng: -6.24836 },
  'RIAM': { lat: 53.33761, lng: -6.24922 },
  'SETU': { lat: 52.25833, lng: -7.11194 },
  'SAC': { lat: 54.28362, lng: -8.46291 },
  'SPCC': { lat: 52.83647, lng: -6.93052 },
  'TUD': { lat: 53.35345, lng: -6.26611 },
  'TUS': { lat: 53.41291, lng: -8.22809 },
  'TCD': { lat: 53.34380, lng: -6.25457 },
  'UCC': { lat: 51.8969, lng: -8.4863 },
  'UCD': { lat: 53.3080, lng: -6.2238 },
  'NUIG': { lat: 53.2799, lng: -9.0605 },
  'UL': { lat: 52.6739, lng: -8.5726 }
};




// Simple helper to compute distance
function distanceBetween(lat1, lng1, lat2, lng2) {
  return getDistance(
    { latitude: lat1, longitude: lng1 },
    { latitude: lat2, longitude: lng2 }
  );
}

// This function pre-labels each listing with the correct `university` property.
function prepareListingsWithUniversity(listings, selectedUni) {
  // 1) If no university filter
  if (!selectedUni) {
    return listings.map((listing) => ({
      ...listing,
    }));
  }

  // 2) Otherwise, check distance for that selected uni
  const uniCoords = universityCoordinates[selectedUni];
  if (!uniCoords) {
    // If coordinates missing, treat them as excluded
    console.warn(`No coordinates for university: ${selectedUni}`);
    return listings.map((listing) => ({
      ...listing,
      university: 'excluded',
    }));
  }

  // 3) For each listing, if within 5km => listing.university = selectedUni
  //    else => listing.university = "excluded"
  return listings.map((listing) => {
    if (!listing.lat || !listing.lng) {
      // Missing lat/lng => skip
      return { ...listing, university: 'excluded' };
    }
    const dist = distanceBetween(uniCoords.lat, uniCoords.lng, listing.lat, listing.lng);
    if (dist <= 5000) {
      return { ...listing, university: selectedUni };
    } else {
      return { ...listing, university: 'excluded' };
    }
  });
}



// Map month abbreviations to numbers for easy comparison
const monthToNumber = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dec: 12
};


// ----- The main component ------
const ExploreView = ({ searchResults = [], loading = false, error = null }) => {
  const containerRef = useRef(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [sortedItems, setSortedItems] = useState(searchResults);

  // Optionally remove or guard this effect if unnecessary:
  useEffect(() => {
    setSortedItems(searchResults);
  }, [searchResults]);

  const feedFilters = { ...selectedFilters };
delete feedFilters.startMonth;
delete feedFilters.endMonth;

  
const preppedListings = useMemo(() => {
  // Apply university proximity filtering first
  let listings = prepareListingsWithUniversity(
    searchResults,
    selectedFilters.university?.[0]
  );

  // Month filtering logic
  const selectedStartMonth = selectedFilters.startMonth?.[0]?.toLowerCase();
  const selectedEndMonth = selectedFilters.endMonth?.[0]?.toLowerCase();

  console.log('Selected Month Filters:', { startMonth: selectedStartMonth, endMonth: selectedEndMonth });

  if (selectedStartMonth || selectedEndMonth) {
    listings = listings.filter(listing => {
      const listingStartMonthRaw = listing.startDate || 'Jan';
      const listingEndMonthRaw = listing.endDate || 'Dec';

      const listingStart = monthToNumber[listingStartMonthRaw.toLowerCase()] || 1;
      const listingEnd = monthToNumber[listingEndMonthRaw.toLowerCase()] || 12;

      const filterStart = selectedStartMonth ? monthToNumber[selectedStartMonth] : null;
      const filterEnd = selectedEndMonth ? monthToNumber[selectedEndMonth] : null;

      console.log(`Listing ID: ${listing.id} - Available: ${listingStartMonthRaw} (${listingStart}) to ${listingEndMonthRaw} (${listingEnd})`);
      console.log(`Filter Range: ${selectedStartMonth || 'none'} (${filterStart}) to ${selectedEndMonth || 'none'} (${filterEnd})`);

      let matches = true;

      if (selectedStartMonth && selectedEndMonth) {
        matches = listingStart <= filterStart && listingEnd >= filterEnd;
      } else if (selectedStartMonth && !selectedEndMonth) {
        matches = listingEnd >= filterStart;
      } else if (!selectedStartMonth && selectedEndMonth) {
        matches = listingStart <= filterEnd;
      }

      console.log(`Listing ID: ${listing.id} - Matches Month Filter: ${matches}`);
      return matches;
    });
  }

  // Rent range filtering logic
  let selectedRentRange = null;
  if (selectedFilters.rentRange?.length) {
    // If the first element is an array and has two elements, unwrap it.
    const possibleRange = selectedFilters.rentRange[0];
    if (Array.isArray(possibleRange) && possibleRange.length === 2) {
      selectedRentRange = possibleRange;
    } else if (selectedFilters.rentRange.length === 2) {
      // Fallback if rentRange is already the expected shape.
      selectedRentRange = selectedFilters.rentRange;
    }
  }

  console.log('Unwrapped Selected Rent Range Filter:', selectedRentRange);

  if (selectedRentRange) {
    const [minRent, maxRent] = selectedRentRange;
    console.log(`Applying Rent Range: ${minRent} to ${maxRent}`);
    listings = listings.filter(listing => {
      const rentValue = Number(listing.rent);
      const isValidNumber = !isNaN(rentValue);
      const withinRange = isValidNumber && rentValue >= minRent && rentValue <= maxRent;

      console.log(
        `Listing ID: ${listing.id}, Rent: ${listing.rent}, Parsed: ${rentValue}, ` +
        `Valid: ${isValidNumber}, Within Range: ${withinRange}`
      );

      return withinRange;
    });
  }

  console.log('Final Filtered Listings:', listings);
  return listings;
}, [searchResults, selectedFilters]);

const {
  rentRange,
  startMonth,
  endMonth,
  ...filtersWithoutExcluded
} = selectedFilters;

  
return (
  <ExploreViewContainer>
    <ButtonContainer>
      <FilterContainer>
        <FilterDrawer onChange={setSelectedFilters} />
      </FilterContainer>
    </ButtonContainer>

    <ScrollableFeedContainer ref={containerRef}>
      <SortContainer>
        <Sort items={preppedListings} onSortedChange={setSortedItems} />
      </SortContainer>
      <Feed
        items={sortedItems}  
        selectedFilters={filtersWithoutExcluded} 
        scrollContainerRef={containerRef}
        loading={loading}
        infiniteScroll={4}
      />
    </ScrollableFeedContainer>
  </ExploreViewContainer>
);
};


export default ExploreView;