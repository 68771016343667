// src/components/Chat.js
import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useAuth } from '../context/AuthContext';
import ProfilePic from './ProfilePic';

// Styled Components

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 800px; /* Increased width for better readability */
  margin: 0 auto;
  justify-content: flex-end;
`;

const LoadingMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
  color: #555;
`;

const ChatMessages = styled.div`
  flex: 1;
  padding: 20px 20px 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align messages to the bottom */
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  ${(props) =>
    props.sent
      ? css`
          justify-content: flex-end;
        `
      : css`
          justify-content: flex-start;
        `}
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.sent ? 'flex-end' : 'flex-start')};
`;

const MessageBubble = styled.div`
  background-color: ${(props) => (props.sent ? '#A855F7' : '#ffffff')};
  color: ${(props) => (props.sent ? '#ffffff' : '#000000')};
  padding: 10px 16px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: ${(props) => (props.sent ? 'right' : 'left')};
  margin:  ${(props) => (props.sent ? '0 0 5px 40px' : '0 40px 5px 0')};
  text-align: left;

  
  
`;

const MessageText = styled.span`
  font-size: 1em;
  
`;

const Avatar = styled.div`
  margin:  ${(props) => (props.sent ? '0 0 25px 5px' : '0 5px 25px 0')};
  
  
`;

const MessageTimestamp = styled.span`
  font-size: 0.7em;
  color: #757575;
  display: block;
  text-align: ${(props) => (props.sent ? 'right' : 'left')};
`;

const ChatInputContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding: 15px 20px;
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 12px 18px;
  border: 1px solid #e0e0e0;
  border-radius: 30px;
  outline: none;
  font-size: 1em;
  transition: border-color 0.3s;

  &:focus {
    border-color: #000;
  }
`;

const SendButton = styled.button`
  margin-left: 15px;
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;


  &:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
`;


const Chat = ({
  conversation,
  handleSendMessage,
  newMessage,
  setNewMessage,
  messagesEndRef,
}) => {
  const { currentUser, userData } = useAuth();

  // Memoize participant map to avoid unnecessary recalculations
  const participantMap = useMemo(() => {
    if (!conversation) return {};
    return conversation.participants.reduce((acc, participant) => {
      acc[participant.uid] = participant;
      return acc;
    }, {});
  }, [conversation]);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    if (conversation && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation, conversation?.messages, messagesEndRef]);

  if (!conversation) {
    return <LoadingMessage>Loading conversation...</LoadingMessage>;
  }

  const { messages = [] } = conversation;

  return (
    <ChatContainer>
      <ChatMessages>
        {/* Wrapper for messages that pushes content to bottom */}
        <div style={{ marginTop: 'auto', width: '100%' }}>
        {messages.map((message) => {
  const isSentByCurrentUser = message.sender === currentUser?.uid;
  const sender = participantMap[message.sender];
  return (
    <MessageContainer
      key={message.id || message.localTimestamp}
      sent={isSentByCurrentUser}
    >
      {!isSentByCurrentUser && sender?.avatarUrl && (
                <Avatar sent={isSentByCurrentUser}>

        <ProfilePic 
          profilePic={sender.avatarUrl} 
          emblem={sender.universityEmblem} 
          size="30px" 
          alt={sender.name} 
        />
        </Avatar>
      )}
      <MessageContent sent={isSentByCurrentUser}>
        <MessageBubble sent={isSentByCurrentUser}>
          <MessageText>{message.text}</MessageText>
        </MessageBubble>
        <MessageTimestamp sent={isSentByCurrentUser}>
          {new Date(message.localTimestamp).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </MessageTimestamp>
      </MessageContent>
      {isSentByCurrentUser && (
        <Avatar sent={isSentByCurrentUser}>
        <ProfilePic 
          profilePic={userData.photoURL || "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"} 
          emblem={userData.universityEmblem} 
          size="30px" 
          alt="You" 
        />
        </Avatar>
      )}
    </MessageContainer>
  );
})}

          <div ref={messagesEndRef} />
        </div>
      </ChatMessages>

      <ChatInputContainer>
        <ChatInput
          type="text"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <SendButton
          onClick={handleSendMessage}
          disabled={!newMessage.trim()}
        >
          Send
        </SendButton>
      </ChatInputContainer>
    </ChatContainer>
  );
};

export default Chat;