// src/components/ChatListingCard.jsx

import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const CardButton = styled.button`
  position: fixed;
  max-width: 800px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  height: 160px;
  cursor: pointer;
  z-index: 40;
`;

const ImageThumbnail = styled.img`
  width: 40%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 16px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const CardTitle = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  color: #111827;
  text-align: left;
`;

const County = styled.span`
  font-size: 1rem;
  color: #6b7280;
  text-align: left;
`;

const CardDetails = styled.div`
  font-size: 1rem;
  margin-top: 8px;
  padding: 5px 8px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
`;

// ChatListingCard Component
export default function ChatListingCard({ data }) {
  const navigate = useNavigate();
  // Assuming data contains a unique identifier for the room, e.g., data.id
  const {
    id,
    title,
    city,
    county,
    images,
    rent,
    startDate,
    endDate,
    type,
  } = data;

  const handleCardClick = () => {
    // Navigate to the room's page using its id
    navigate(`/rooms/${id}`);
  };

  return (
    <CardButton onClick={handleCardClick}>
      {images && images.length > 0 && (
        <ImageThumbnail src={images[0]} alt={`${type} in ${city}`} />
      )}
      <CardContent>
        <CardTitle>{title}</CardTitle>
        <County>
          {city}, {county}
        </County>
        <DetailsContainer>
          <CardDetails>€{rent}</CardDetails>
          <CardDetails>
            {startDate} - {endDate}
          </CardDetails>
        </DetailsContainer>
      </CardContent>
    </CardButton>
  );
}
