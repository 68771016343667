// Feed.jsx
import React from 'react';
import styled from 'styled-components';
import { FeedLogic } from 'liamc9npm';
import ListingCard from '../ListingCard';  
import { GhostLoader } from 'liamc9npm';   

const FeedContainer = styled.div`
  display: grid;
  gap: 1rem;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 6rem;

  /* Responsive grid: single column on small screens, multiple columns on larger screens */
  grid-template-columns: 1fr; /* Default: single column */

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
  }
`;

const NoListingsMessage = styled.div`
  grid-column: 1 / -1; /* Make message span full width of grid */
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  padding: 2rem;
`;

const Feed = ({
  items = [],
  selectedFilters = {},
  sortBy,
  pagination,
  loadMore,
  infiniteScroll,
  scrollContainerRef,
  loading
}) => {
  // Destructure the needed logic from FeedLogic
  const {
    itemsToRender,
    pages,
    currentPage,
    setCurrentPage,
    hasMoreItems,
    handleLoadMore
  } = FeedLogic({
    items,
    selectedFilters,
    sortBy,
    pagination,
    loadMore,
    infiniteScroll,
    scrollContainerRef
  });

  console.log('FeedLogic itemsToRender:', itemsToRender);

  if (loading) {
    return (
      <FeedContainer>
        <GhostLoader />
      </FeedContainer>
    );
  }

  return (
    <FeedContainer>
      {itemsToRender.length === 0 ? (
        <NoListingsMessage><p>Lettz is a brand new platform</p><p> Be the first to sublet your room!</p></NoListingsMessage>
      ) : (
        itemsToRender.map((item, idx) => (
          <ListingCard key={item.id || idx} data={item} />
        ))
      )}
    </FeedContainer>
  );
};

export default Feed;
