import React, { useState } from 'react';
import styled from 'styled-components';
import {
  BottomDrawer,
  SelectInput,
  RangeSlider,
  CheckedItem,
  BedIcon,
  TwoBedsIcon,
  FilterLogic,
  MaleIcon3,
  FemaleIcon3,
  CoupleIcon,
} from 'liamc9npm';

// ---------------------- Styled Components ----------------------

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);


  &:focus {
    outline: none;
  }
`;



const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const SectionLeft = styled.div`
  flex: 1;
  text-align: left;
  color: #000;
  font-weight: 600;
  font-size: 1.3rem;
  padding: 0 16px;
`;

const SectionRight = styled.div`
  flex: 1;
  text-align: left;
  color: #666;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 0 16px;
`;

const Divider = styled.div`
  width: 2px;
  height: 30px;
  background-color: #888;
  display: block;
`;

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 8rem;
  min-height: 300px;
`;

const DrawerHeader = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  color: #000;
  text-align: center;
`;

const MonthsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  margin: 10px 0;
`;

const FixedButtonBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  z-index: 10;
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ primary }) => (primary ? '#A855F7' : '#e0e0e0')};
  color: ${({ primary }) => (primary ? '#fff' : '#333')};
`;


const HorizontalGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

// New styled component for headers
const SectionHeader = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
`;


// Container to control the width of the RangeSlider
const RangeContainer = styled.div`
  width: 80%;  /* Adjust width as needed */
  margin: 0 auto;
`;

// ---------------------- Options Data ----------------------
const universitiesOptions = [
  { value: 'ATU', label: 'Atlantic Technological University' },
  { value: 'DCU', label: 'Dublin City University' },
  { value: 'DKIT', label: 'Dundalk Institute of Technology' },
  { value: 'IADT', label: 'Dún Laoghaire Institute of Art, Design and Technology' },
  { value: 'MIE', label: 'Marino Institute of Education' },
  { value: 'MIC', label: 'Mary Immaculate College' },
  { value: 'MU', label: 'Maynooth University' },
  { value: 'MTU', label: 'Munster Technological University' },
  { value: 'NCAD', label: 'National College of Art and Design' },
  { value: 'NCI', label: 'National College of Ireland' },
  { value: 'RCSI', label: 'Royal College of Surgeons in Ireland' },
  { value: 'RIA', label: 'Royal Irish Academy' },
  { value: 'RIAM', label: 'Royal Irish Academy of Music' },
  { value: 'SETU', label: 'South East Technological University' },
  { value: 'SAC', label: 'St. Angela’s College' },
  { value: 'SPCC', label: 'St. Patrick’s, Carlow College' },
  { value: 'TUD', label: 'Technological University Dublin' },
  { value: 'TUS', label: 'Technological University of the Shannon' },
  { value: 'TCD', label: 'Trinity College Dublin' },
  { value: 'UCC', label: 'University College Cork' },
  { value: 'UCD', label: 'University College Dublin' },
  { value: 'NUIG', label: 'University of Galway' },
  { value: 'UL', label: 'University of Limerick' }
];


const monthsOptions = [
  { value: '', label: 'Any' },
  { value: 'jan', label: 'Jan' },
  { value: 'feb', label: 'Feb' },
  { value: 'mar', label: 'Mar' },
  { value: 'apr', label: 'Apr' },
  { value: 'may', label: 'May' },
  { value: 'jun', label: 'Jun' },
  { value: 'jul', label: 'Jul' },
  { value: 'aug', label: 'Aug' },
  { value: 'sep', label: 'Sep' },
  { value: 'oct', label: 'Oct' },
  { value: 'nov', label: 'Nov' },
  { value: 'dec', label: 'Dec' },
];

const otherTenantsOptions = [
  { value: 'any', label: 'Any' },
  { value: 'All Male', label: 'All Male' },
  { value: 'All Female', label: 'All Female' },
];


// ---------------------- Filter Config ----------------------
const filtersConfig = [
  {
    category: 'university',
    label: 'University',
    type: 'dropdown',
    options: universitiesOptions,
  },
  {
    category: 'startMonth',
    label: 'From Month',
    type: 'dropdown',
    options: monthsOptions,
  },
  {
    category: 'endMonth',
    label: 'To Month',
    type: 'dropdown',
    options: monthsOptions,
  },
  {
    category: 'rentRange',
    label: 'Rent Range',
    type: 'range',
    options: [],
  },
  {
    category: 'type',
    label: 'Type(s)',
    type: 'checkbox',
    options: [
      { value: 'Single', label: 'Single Room' },
      { value: 'Double', label: 'Double Room' },
    ],
  },
];

// ---------------------- FilterDrawer Component ----------------------
const FilterDrawer = ({ onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filtersState, setFiltersState] = useState({});
  const [sliderResetKey, setSliderResetKey] = useState(0);

  const handleOpenDrawer = () => setIsOpen(true);
  const handleCloseDrawer = () => setIsOpen(false);

  const place = filtersState.university?.[0] ? `Near ${filtersState.university[0]}` : "Anywhere";
  const startDate = filtersState.startMonth?.[0] || "Anytime";
  const endDate = filtersState.endMonth?.[0] || "";

  return (
    <>
      <StyledButton onClick={handleOpenDrawer}>
        <ContentContainer>
          <SectionLeft>{place}</SectionLeft>
          <Divider />
          <SectionRight>
            {startDate}
            {endDate && ` - ${endDate}`}
          </SectionRight>
        </ContentContainer>
      </StyledButton>

      <BottomDrawer isOpen={isOpen} onClose={handleCloseDrawer}>
        <FilterLogic
          filters={filtersConfig}
          onChange={selectedFilters => {
            const filtersForFeed = { ...selectedFilters };

            if (filtersForFeed.otherTenants && filtersForFeed.otherTenants.includes('any')) {
              filtersForFeed.otherTenants = [];
            }

            setTimeout(() => {
              if (onChange) onChange(filtersForFeed);
            }, 0);

            setFiltersState(selectedFilters);
          }}
        >
          {({ selectedFilters, setSelection, clearAll }) => (
            <DrawerContainer>
              <DrawerHeader>Filters</DrawerHeader>

              {/* University Section */}
              <SelectInput
                name="university"
                label="University (Rooms within 5km)"
                color="#A855F7"
                value={selectedFilters.university?.[0] || ''}
                onChange={(e) => setSelection('university', e.target.value)}
                options={filtersConfig.find(f => f.category === 'university').options}
              />

              {/* Months Section */}
              <MonthsContainer>
                <SelectInput
                  name="startMonth"
                  label="From"
                  color="#A855F7"
                  value={selectedFilters.startMonth?.[0] || ''}
                  onChange={(e) => setSelection('startMonth', e.target.value)}
                  options={filtersConfig.find(f => f.category === 'startMonth').options}
                />
                <SelectInput
                  name="endMonth"
                  label="To"
                  color="#A855F7"
                  value={selectedFilters.endMonth?.[0] || ''}
                  onChange={(e) => setSelection('endMonth', e.target.value)}
                  options={filtersConfig.find(f => f.category === 'endMonth').options}
                />
              </MonthsContainer>

              {/* Type Section */}
              <HorizontalGroup>
                {filtersConfig
                  .find(f => f.category === 'type')
                  .options.map((opt) => {
                    const isChecked = selectedFilters.type?.includes(opt.value);
                    let IconComponent = null;
                    let iconSizeClass = "w-10 h-10";  // Default size
                    if (opt.value === 'Single') {
                      IconComponent = BedIcon;
                    } else if (opt.value === 'Double') {
                      IconComponent = TwoBedsIcon;
                      iconSizeClass = "w-16 h-16";
                    }

                    return (
                      <CheckedItem
                        key={opt.value}
                        color="#A855F7"
                        label={opt.label}
                        height='6rem'
                        width='6rem'
                        checked={!!isChecked}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelection('type', opt.value);
                          } else {
                            setSelection('type', opt.value, true);
                          }
                        }}
                        svg={IconComponent ? <IconComponent className={iconSizeClass} /> : null}
                      />
                    );
                  })}
              </HorizontalGroup>

              {/* Rent Range Section */}
              <RangeContainer>
                <RangeSlider
                  key={sliderResetKey} 
                  min={0}
                  max={3000}
                  step={50}
                  minimumGap={100}
                  label="Rent"
                  valuePrefix="€"
                  value={
                    selectedFilters.rentRange?.length
                      ? selectedFilters.rentRange
                      : [0, 3000]
                  }
                  onChange={(range) => setSelection('rentRange', range)}
                />
              </RangeContainer>

              {/* Other Tenants Section */}
              <SectionHeader>Other Tenants</SectionHeader>
              <HorizontalGroup>
                {otherTenantsOptions.map((opt) => {
                  const isChecked = selectedFilters.otherTenants?.includes(opt.value);
                  let IconComponent = null;
                  if (opt.value === 'any') IconComponent = CoupleIcon;
                  else if (opt.value === 'All Male') IconComponent = MaleIcon3;
                  else if (opt.value === 'All Female') IconComponent = FemaleIcon3;

                  return (
                    <CheckedItem
                      key={opt.value}
                      color="#A855F7"
                      label={opt.label}
                      checked={!!isChecked}
                      height='6rem'
                      width='6rem'
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelection('otherTenants', opt.value);
                        } else {
                          setSelection('otherTenants', opt.value, true);
                        }
                      }}
                      svg={IconComponent ? <IconComponent className="w-12 h-12" /> : null}
                    />
                  );
                })}
              </HorizontalGroup>

              <FixedButtonBar>
                <ActionButton
                  onClick={() => {
                    clearAll();
                    setSliderResetKey(prev => prev + 1);
                  }}
                >
                  Clear
                </ActionButton>
                <ActionButton primary onClick={handleCloseDrawer}>
                  Apply
                </ActionButton>
              </FixedButtonBar>
            </DrawerContainer>
          )}
        </FilterLogic>
      </BottomDrawer>
    </>
  );
};

export default FilterDrawer;