// ListingForm.jsx
import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { Input, CheckedItem, SelectInput } from "liamc9npm";
import {
  ChevronLeftIcon,
  BedIcon,
  TwoBedsIcon,
  CoupleIcon,
  MaleIcon3,
  FemaleIcon3,
} from "liamc9npm";
import ImageUploading from "./ImageUploading";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useJsApiLoader } from "@react-google-maps/api";

const INITIAL_FORM_STATE = {
  streetAddress: "",
  city: "",
  county: "",
  eircode: "",
  rent: "",
  startDate: "",
  endDate: "",
  type: "",
  description: "",
  otherTenants: "",
};

const ListingForm = ({ onClose, onSubmit, initialData }) => {
  const functions = getFunctions();
  const [currentPage, setCurrentPage] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isNavigating, setIsNavigating] = useState(false); // New state for navigation lock
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAIRbf2TllYWSlRZasuVy7z623xajzkt_U",
  });

  const getLocation = httpsCallable(functions, "getLocation");

  // Refs for each form page
  const formRefs = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    if (initialData) {
      setFormData({ ...INITIAL_FORM_STATE, ...initialData });
      if (initialData.images?.length) {
        setUploadedImages(
          initialData.images.map((url) => ({
            id: uuidv4(),
            file: null,
            preview: url,
            url,
          }))
        );
      }
    }
  }, [initialData]);

  const handleChange = useCallback((key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  }, []);

  const resetForm = useCallback(() => {
    setCurrentPage(0);
    setFormData(INITIAL_FORM_STATE);
    uploadedImages.forEach((image) => {
      if (image.preview && image.file) {
        URL.revokeObjectURL(image.preview);
      }
    });
    setUploadedImages([]);
    onClose();
  }, [uploadedImages, onClose]);

  const handleNext = useCallback(async () => {
    if (isNavigating) return;
    setIsNavigating(true);
  
    const currentFormRef = formRefs[currentPage];
    if (currentFormRef?.current) {
      const isValid = currentFormRef.current.checkValidity();
      if (!isValid) {
        currentFormRef.current.reportValidity();
        setIsNavigating(false);
        return;
      }
    }
  
    if (currentPage === 0) {
      try {
        const response = await getLocation({ eircode: formData.eircode });
        const { lat, lng } = response.data || {};
        if (!lat || !lng) {
          toast.error("You must enter a valid Eircode.", {
            position: "top-center",
            autoClose: 5000,
            pauseOnHover: false,
          });
          setIsNavigating(false);
          return;
        }
      } catch (error) {
        console.error("Error fetching location:", error);
        toast.error("You must enter a valid Eircode.", {
          position: "top-center",
          autoClose: 5000,
          pauseOnHover: false,
        });
        setIsNavigating(false);
        return;
      }
    }
  
    // Page 2 validation...
    if (currentPage === 1) {
      if (!formData.type) {
        toast.error("Please select a room type.", {
          position: "top-center",
          autoClose: 5000,
        });
        setIsNavigating(false);
        return;
      }
      if (!formData.otherTenants) {
        toast.error("Please select a preference for other tenants.", {
          position: "top-center",
          autoClose: 5000,
        });
        setIsNavigating(false);
        return;
      }
    }
  
    setCurrentPage(prev => prev + 1);
    setIsNavigating(false);
  }, [isNavigating, currentPage, formData, getLocation, formRefs]);
  
  const handleSubmit = useCallback(async () => {
    const currentFormRef = formRefs[currentPage];
    if (currentFormRef.current) {
      const isValid = currentFormRef.current.checkValidity();
      if (!isValid) {
        currentFormRef.current.reportValidity();
        return;
      }
    }

    setIsSubmitting(true);

    if (uploadedImages.length === 0) {
      toast.error("Please upload at least one image.", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsSubmitting(false);
      return;
    }

    let lat = null;
    let lng = null;
    try {
      const response = await getLocation({ eircode: formData.eircode });
      ({ lat, lng } = response.data || {});
    } catch (error) {
      console.error("Error calling getLocation function:", error);
      toast.error("Unable to verify location. Please check your Eircode.", {
        position: "top-center",
        autoClose: 5000,
      });
    }

    const imageFiles = uploadedImages.filter((img) => img.file).map((img) => img.file);
    const imageUrls = uploadedImages.filter((img) => img.url).map((img) => img.url);
    const combinedImages = [...imageUrls, ...imageFiles];
    const validImages = imageFiles.filter((file) => file instanceof File);
    if (validImages.length !== imageFiles.length) {
      toast.warn("Some images are invalid and will not be submitted.", {
        position: "top-center",
        autoClose: 5000,
      });
    }

    onSubmit({ ...formData, images: combinedImages, lat, lng });
    resetForm();
    setIsSubmitting(false);
  }, [formData, uploadedImages, onSubmit, resetForm, getLocation, formRefs, currentPage]);

  const pageHeadings = ["Location", "Sublet Details", "Images"];
  const pages = [
    <PageOne key="page1" formData={formData} handleChange={handleChange} ref={formRefs[0]} />,
    <PageTwo key="page2" formData={formData} handleChange={handleChange} ref={formRefs[1]} />,
    <PageThree key="page3" uploadedImages={uploadedImages} setUploadedImages={setUploadedImages} ref={formRefs[2]} />,
  ];

  const progressPercentage = ((currentPage + 1) / pages.length) * 100;

  return (
    <FormContainer>
      {currentPage === 0 && (
        <BackButton onClick={resetForm} aria-label="Close form">
          <ChevronLeftIcon className="w-8 h-8" />
        </BackButton>
      )}
      <Heading>{pageHeadings[currentPage]}</Heading>
      <ProgressBar
        role="progressbar"
        aria-valuenow={progressPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <ProgressFill style={{ width: `${progressPercentage}%` }} />
      </ProgressBar>
      <Content>{pages[currentPage]}</Content>
      <NavButtons hasPrev={currentPage > 0}>
        {currentPage > 0 && (
          <PrevButton onClick={() => setCurrentPage((prev) => prev - 1)}>Previous</PrevButton>
        )}
        {currentPage < pages.length - 1 ? (
<NextButton 
  onClick={handleNext} 
  disabled={isNavigating} 
  style={{ opacity: isNavigating ? 0.6 : 1, cursor: isNavigating ? "not-allowed" : "pointer" }}
>
  Next
</NextButton>
    ) : (
          <SubmitButton
            onClick={handleSubmit}
            disabled={isSubmitting}
            style={{ opacity: isSubmitting ? 0.6 : 1, cursor: isSubmitting ? "not-allowed" : "pointer" }}
          >
            {isSubmitting ? "Submitting..." : initialData ? "Update" : "Submit"}
          </SubmitButton>
        )}
      </NavButtons>
    </FormContainer>
  );
};

// Forwarding refs to child components
const PageOne = React.forwardRef(({ formData, handleChange }, ref) => (
  <FormSection as="form" ref={ref} noValidate>
    <SectionHeader>Address</SectionHeader>
    <Input
      name="streetAddress"
      type="text"
      label="Street Address"
      color="#A855F7"
      value={formData.streetAddress}
      onChange={(e) => handleChange("streetAddress", e.target.value)}
      required
    />
    <Input
      name="city"
      type="text"
      label="Town/City"
      color="#A855F7"
      value={formData.city}
      onChange={(e) => handleChange("city", e.target.value)}
      required
    />
    <SelectInput
      name="county"
      label="County"
      value={formData.county}
      onChange={(e) => handleChange("county", e.target.value)}
      color="#A855F7"
      options={countiesOptions}
      required
    />
    <Input
      name="eircode"
      type="text"
      label="Eircode"
      color="#A855F7"
      value={formData.eircode}
      onChange={(e) =>
        handleChange("eircode", e.target.value.toUpperCase().replace(/\s/g, "").slice(0, 7))
      }
      required
      pattern="[A-Z0-9]{7}" // Example pattern for Eircode
      title="Please enter a valid Eircode (7 characters)."
    />
  </FormSection>
));

const PageTwo = React.forwardRef(({ formData, handleChange }, ref) => (
  <FormSection as="form" ref={ref} noValidate>
    <SectionHeader>Sublet Details</SectionHeader>
    <Input
      name="rent"
      type="number"
      label="Monthly Rent (€)"
      color="#A855F7"
      value={formData.rent}
      onChange={(e) => handleChange("rent", e.target.value)}
      required
      min="0"
      step="0.01"
    />
    <DateSelectors>
      <SelectInput
        name="startDate"
        label="Move In"
        color="#A855F7"
        value={formData.startDate}
        onChange={(e) => handleChange("startDate", e.target.value)}
        options={startMonthsOptions}  // Use startMonthsOptions here
        required
      />
      <SelectInput
        name="endDate"
        label="Move Out"
        color="#A855F7"
        value={formData.endDate}
        onChange={(e) => handleChange("endDate", e.target.value)}
        options={endMonthsOptions}   // Use endMonthsOptions here
        required
      />
    </DateSelectors>
    <Input
      name="description"
      type="textarea"
      label="Description"
      color="#A855F7"
      minHeight="150px"
      value={formData.description}
      onChange={(e) => handleChange("description", e.target.value)}
      required
    />
    <SectionHeader>Room Type</SectionHeader>
    <CheckboxGroup>
      <CheckedItem
        key="Single"
        label="Single"
        height="5rem"
        width="5rem"
        color="#A855F7"
        checked={formData.type === "Single"}
        onChange={() => handleChange("type", "Single")}
        svg={<BedIcon className="w-6 h-6" />}
      />
      <CheckedItem
        key="Double"
        label="Double"
        height="5rem"
        width="5rem"
        color="#A855F7"
        checked={formData.type === "Double"}
        onChange={() => handleChange("type", "Double")}
        svg={<TwoBedsIcon className="w-12 h-12" />}
      />
    </CheckboxGroup>
    <SectionHeader>Other Tenants</SectionHeader>
    <CheckboxGroup>
      <CheckedItem
        key="All Male"
        label="All Male"
        height="5rem"
        width="5rem"
        color="#A855F7"
        checked={formData.otherTenants === "All Male"}
        onChange={() => handleChange("otherTenants", "All Male")}
        svg={<MaleIcon3 className="w-10 h-10" />}
      />
      <CheckedItem
        key="All Female"
        label="All Female"
        height="5rem"
        width="5rem"
        color="#A855F7"
        checked={formData.otherTenants === "All Female"}
        onChange={() => handleChange("otherTenants", "All Female")}
        svg={<FemaleIcon3 className="w-10 h-10" />}
      />
      <CheckedItem
        key="Mixed"
        label="Mixed"
        height="5rem"
        width="5rem"
        color="#A855F7"
        checked={formData.otherTenants === "Mixed"}
        onChange={() => handleChange("otherTenants", "Mixed")}
        svg={<CoupleIcon className="w-10 h-10" />}
        />
    </CheckboxGroup>
  </FormSection>
));

const PageThree = React.forwardRef(({ uploadedImages, setUploadedImages }, ref) => (
  <FormSection as="form" ref={ref} noValidate>
    <ImageUploading
      uploadedImages={uploadedImages}
      setUploadedImages={setUploadedImages}
      maxImages={6}
      acceptedFormats={["image/jpeg", "image/png", "image/gif"]}
      customMessages={{
        maxLimit: "You can only upload up to {maxImages} images.",
        invalidFormat: "Only JPG, PNG, and GIF formats are allowed.",
        success: "You have successfully uploaded {count} images.",
      }}
    />
  </FormSection>
));

// Options and Styled Components...

const countiesOptions = [
  "Antrim",
  "Armagh",
  "Carlow",
  "Cavan",
  "Clare",
  "Cork",
  "Derry",
  "Donegal",
  "Down",
  "Dublin",
  "Fermanagh",
  "Galway",
  "Kerry",
  "Kildare",
  "Kilkenny",
  "Laois",
  "Leitrim",
  "Limerick",
  "Longford",
  "Louth",
  "Mayo",
  "Meath",
  "Monaghan",
  "Offaly",
  "Roscommon",
  "Sligo",
  "Tipperary",
  "Tyrone",
  "Waterford",
  "Westmeath",
  "Wexford",
  "Wicklow",
].map((county) => ({ value: county, label: county }));

const startMonthsOptions = [
  { value: "Now", label: "Now" },
  { value: "Jan", label: "Jan" },
  { value: "Feb", label: "Feb" },
  { value: "Mar", label: "Mar" },
  { value: "Apr", label: "Apr" },
  { value: "May", label: "May" },
  { value: "Jun", label: "Jun" },
  { value: "Jul", label: "Jul" },
  { value: "Aug", label: "Aug" },
  { value: "Sept", label: "Sept" },
  { value: "Oct", label: "Oct" },
  { value: "Nov", label: "Nov" },
  { value: "Dec", label: "Dec" },
];

const endMonthsOptions = [
  { value: "", label: "Select Month..." },
  { value: "Any", label: "Any" },
  { value: "Jan", label: "Jan" },
  { value: "Feb", label: "Feb" },
  { value: "Mar", label: "Mar" },
  { value: "Apr", label: "Apr" },
  { value: "May", label: "May" },
  { value: "Jun", label: "Jun" },
  { value: "Jul", label: "Jul" },
  { value: "Aug", label: "Aug" },
  { value: "Sept", label: "Sept" },
  { value: "Oct", label: "Oct" },
  { value: "Nov", label: "Nov" },
  { value: "Dec", label: "Dec" },
];


// Styled Components
const FormContainer = styled.div`
  position: relative;
  max-width: 600px;
  margin: 0px auto 40px;
  padding: 15px 20px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  border-radius: 8px;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.75rem;
  color: #333333;
`;

const Content = styled.div`
  flex: 1;
  margin-bottom: 80px;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #e5e7eb;
  height: 8px;
  border-radius: 4px;
  margin-bottom: 24px;
`;

const ProgressFill = styled.div`
  background-color: #a855f7;
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease-in-out;
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: ${({ hasPrev }) => (hasPrev ? "space-between" : "flex-end")};
  padding: 14px 10px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #e0e0e0;
  z-index: 1000;
`;

const PrevButton = styled.button`
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #000000;
  color: #fff;
  transition: background-color 0.2s;
`;

const NextButton = styled.button`
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #000000;
  color: #fff;
  transition: background-color 0.2s;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #a855f7;
  color: #fff;
  transition: background-color 0.2s;
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;

  @media (max-width: 640px) {
    top: 15px;
    left: 15px;
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CheckboxGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const DateSelectors = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const SectionHeader = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
`;

export default ListingForm;
