// Import the collegeData at the top of your file
import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext'; // Adjust the path according to your file structure
import LoginView from '../views/LoginView'; // Assuming this is the UI component you're using
import { useNavigate, useLocation } from "react-router-dom";

  // List of allowed college email domains and their corresponding university names and emblems
  const collegeData = {
    "@universityofgalway.ie": {
      name: "NUI Galway",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/nuig.png?alt=media&token=889a886b-5b0a-459c-b4b2-416722fc5e70",
      shortName: "NUIG",
    },
    "@studentmail.ul.ie": {
      name: "University of Limerick",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/UL.png?alt=media&token=c78a6025-71d9-4ca6-8575-0d40cfc01416",
      shortName: "UL",
    },
    "@mumail.ie": {
      name: "Maynooth University",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/MU.png?alt=media&token=0dbab095-2ac7-403f-9316-034fe4994b18",
      shortName: "MU",
    },
    "@mytudublin.ie": {
      name: "Technological University Dublin",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/TUD.png?alt=media&token=30ffeff0-f325-46a6-b2b6-42b4af62c441",
      shortName: "TUD",
    },
    "@atu.ie": {
      name: "Atlantic Technological University",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/ATU.png?alt=media&token=6e4824e5-d6ef-41f9-a184-d057b345a2eb",
      shortName: "ATU",
    },
    "@student.iadt.ie": {
      name: "Institute of Art, Design and Technology",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/IADT.png?alt=media&token=7e019d94-c487-402a-bdcf-005de6410f3c",
      shortName: "IADT",
    },
    "@micstudent.mic.ul.ie": {
      name: "Mary Immaculate College",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/MIC.png?alt=media&token=6afb5ed4-4eed-4b00-92f8-e19982b7f6be",
      shortName: "MIC",
    },
    "@rcsi.com": {
      name: "Royal College of Surgeons Ireland",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/RCSI.png?alt=media&token=12bd68cb-569c-4481-8ca4-f18115581b8c",
      shortName: "RCSI",
    },
    "@student.ncad.ie": {
      name: "National College of Art and Design",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/ncad.png?alt=media&token=4997f93d-e97a-450d-9d1c-81ef79c026e2",
      shortName: "NCAD",
    },
    "@student.griffith.ie": {
      name: "Griffith College Dublin",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/ucc.png?alt=media&token=2efd6653-16f4-4955-9821-14467276b6ef",
      shortName: "GCD",
    },
    "@mymtu.ie": {
      name: "Munster Technological University",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/MTU.png?alt=media&token=7cc01df9-bc32-4a9f-a2d4-85f223d616e7",
      shortName: "MTU",
    },
    "@tcd.ie": {
      name: "Trinity College Dublin",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/TCD.png?alt=media&token=2e471bd6-581f-42da-ac51-f36bf48ed687",
      shortName: "TCD",
    },
    "@ucdconnect.ie": {
      name: "University College Dublin",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/UCD.png?alt=media&token=28fe8703-8040-4be1-962e-642f05f435cc",
      shortName: "UCD",
    },
    "@mail.dcu.ie": {
      name: "Dublin City University",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/DCU.png?alt=media&token=ebbe7a88-ac7c-4b14-b5cb-b0cef028632c",
      shortName: "DCU",
    },
    "@umail.ucc.ie": {
      name: "University College Cork",
      emblem:
        "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/ucc.png?alt=media&token=2efd6653-16f4-4955-9821-14467276b6ef",
      shortName: "UCC",
    },
    "@setu.ie": {
      name: "South East Technological University",
      emblem: "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/SETU.png?alt=media&token=1a26dcd3-a00d-4362-95f4-242d2880bd9f",
      shortName: "SETU",
    },
    "@tus.ie": {
      name: "Technological University of the Shannon",
      emblem: "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/TUS.png?alt=media&token=2bb73bf0-b032-4aff-b23c-a1bad9696fc4",
      shortName: "TUS",
    },
    "@mie.ie": {
      name: "Marino Institute of Education",
      emblem: "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/MIE.png?alt=media&token=dc7c8b28-76cb-483b-b1e9-0003bb4ee1b4",
      shortName: "MIE",
    },
    "@carlowcollege.ie": {
      name: "St. Patrick’s, Carlow College",
      emblem: "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/SPCC.png?alt=media&token=ed43cf11-2ef5-434b-b7ca-3a353494d73f",
      shortName: "SPCC",
    },
    "@riam.ie": {
      name: "Royal Irish Academy of Music",
      emblem: "https://firebasestorage.googleapis.com/v0/b/lettz-70fa3.firebasestorage.app/o/RIAM.png?alt=media&token=fa1dff46-7b2a-4d82-81b2-ea0f8adff24a",
      shortName: "RIAM",
    },
  };
  

const Login = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reenterPassword, setReenterPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSignupComplete, setIsSignupComplete] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { login, signup, resetPassword, loginWithGoogle, loginWithApple, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from || "/"; // Default to home if no redirect route

  // Handle user login
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setResetEmailSent(false);
    setIsLoading(true);
    try {
      const userCredential = await login(email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        navigate(from); // Redirect to the original page
      } else {
        await logout();
        setError('Your email is not verified. Please verify your email.');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError('Invalid email or password. Please try again.');
    }
  };

  // Handle user signup
  const handleSignup = async (e) => {
    e.preventDefault();

    // Check password match
    if (password !== reenterPassword) {
      setError('Passwords do not match.');
      return;
    }

    // Check terms acceptance
    if (!termsAccepted) {
      setError('You must accept the terms and conditions.');
      return;
    }

    // Validate that the email uses a permitted college domain
    const collegeEntry = Object.entries(collegeData).find(([domain]) =>
      email.toLowerCase().endsWith(domain)
    );
    if (!collegeEntry) {
      setError('You must use a valid college email to sign up.');
      return;
    }

    // Extract university information from the matching domain
    const [domain, { name, emblem, shortName }] = collegeEntry;

    try {
      setIsLoading(true);
      // Pass extra university data as part of the signup process.
      // This assumes your `signup` function can handle additional user profile data as a fourth argument.
      await signup(email, password, username, {
        universityName: name,
        universityEmblem: emblem,
        universityShortName: shortName,
      });


      setIsSignupComplete(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  // Handle forgot password
  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address.');
      setResetEmailSent(false);
      return;
    }

    try {
      await resetPassword(email);
      setResetEmailSent(true);
      setError('');
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        setError('No account found with this email address.');
      } else {
        setError('Failed to send password reset email. Please try again.');
      }
      setResetEmailSent(false);
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    try {
      await loginWithGoogle();
      navigate('/explore'); // Replace with your desired route
    } catch (error) {
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  return (
    <div>
      <LoginView
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        username={username}
        setUsername={setUsername}
        reenterPassword={reenterPassword}
        setReenterPassword={setReenterPassword}
        error={error}
        isSignupComplete={isSignupComplete}
        setIsSignupComplete={setIsSignupComplete}
        isLoading={isLoading}
        termsAccepted={termsAccepted}
        setTermsAccepted={setTermsAccepted}
        handleSignup={handleSignup}
        handleLogin={handleLogin}
        setShowSignUp={setShowSignUp}
        isSignUp={showSignUp}
        onForgotPassword={handleForgotPassword}
        resetEmailSent={resetEmailSent}
        onGoogleSignIn={handleGoogleSignIn}
        themeColor="#A855F7"
      />
    </div>
  );
};

export default Login;
